import dayjs from "dayjs";
import axios from "axios";
import { Modal, message } from "antd";
import TimeLine from "./TimeLine";
import { UserStates } from "../../context/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { changeOrderStatusUrl, ordersUrl } from "../../apis/constants";

import styles from "./cart.module.css";
import dummyPdtImg from "../../assets/dummyPdtImg.jpeg";

const MyOrders = () => {
  const { accessToken, userId, getCart } = useContext(UserStates);

  const [show, setShow] = useState();
  const [orders, setOrders] = useState();
  const [selectedIndex, setSelectedIndex] = useState();

  const getOrdersHistory = () => {
    const config = {
      method: "post",
      url: `${ordersUrl}${userId}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setOrders(res.data.data))
      .catch((err) => err);
  };

  const cancelOrder = (id) => {
    const config = {
      method: "patch",
      url: `${changeOrderStatusUrl}${id}`,
      headers: { Authorization: accessToken },
      data: {
        date: new Date(),
        status: "Cancelled",
        userId,
      },
    };

    axios(config)
      .then((res) => {
        getCart();
        // window.location.reload();
        getOrdersHistory();
        message.success("Order cancelled successfully");
      })
      .catch((err) => err);
  };

  useEffect(() => {
    getOrdersHistory();
  }, []);

  return (
    <div className="bg-[#F5F5F5]" style={{ marginBottom: "60px" }}>
      {orders?.map((order, index) => (
        <div className={styles.container}>
          <div className={styles.image}>
            <img
              src={order?.product[0]?.productData?.images[0] || dummyPdtImg}
              alt={`gefmart order item ${order?.product[0]?.productData?.name}`}
            />
          </div>
          <div className={styles.content}>
            <div
              className={`${styles.nameAndActionBtn} flex-col md:flex-row gap-1`}
            >
              <div className="flex gap-2">
                <div className="w-3/4 lg:w-full">
                  <h1 style={{ fontSize: "15px", fontWeight: "500" }}>
                    {/* {order?.product[0]?.productData?.name} */}
                    {`ORDER ID: GEFORD${order?.order_id}`}
                  </h1>
                  <p
                    className={`text-gray-500 text-wrap ${styles.description}`}
                  >
                    {order?.product[0]?.productData?.description}
                  </p>
                  <p className={styles.placedOnText}>
                    Placed on{" "}
                    {dayjs(order?.statuses[0]?.date).format(
                      "ddd, DD-MMM-YYYY hh:mm a"
                    )}
                  </p>
                  {order?.product?.map((product) => (
                    <>
                      {product?.total_quantity !== 0 && (
                        <div className={styles.mappedPdtContainer}>
                          <img
                            src={product?.productData?.images[0] || dummyPdtImg}
                            alt={`gefmart order item ${order?.product[0]?.productData?.name}`}
                          />
                          <div>
                            <p>Name: {product?.productData?.name}</p>
                            <p>Qty: {product?.total_quantity}</p>
                            <p>Price: {product?.rate}</p>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                  <p className={styles.totalPrice}>
                    TOTAL PRICE: {order?.total_amount}
                  </p>
                </div>
              </div>

              <div className={styles.bottomContent}>
                {order?.statuses?.length === 1 && (
                  <button
                    onClick={() => {
                      Modal.confirm({
                        title: "Cancel Order",
                        type: "error",
                        content: "Are you sure you want to cancel this order?",
                        okText: "Yes, Cancel",
                        cancelText: "No",
                        okType: "danger",
                        okButtonProps: {
                          style: {
                            backgroundColor: "#8B0000", // Medium dark red
                            color: "#FFFFFF", // White text
                            border: "none", // Remove border if needed
                          },
                        },
                        onOk() {
                          cancelOrder(order?._id);
                        },
                        onCancel() {
                          console.log("User decided not to cancel");
                        },
                      });
                    }}
                    className={styles.cancelOrderBtn}
                  >
                    Cancel Order?
                  </button>
                )}
                <p
                  className={`${
                    show && selectedIndex === index
                      ? `text-slate-400`
                      : `text-[#00BF63]`
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setSelectedIndex(index);
                    if (show && selectedIndex === index) {
                      setShow(!show);
                    } else {
                      setShow(true);
                    }
                  }}
                >
                  {show && selectedIndex === index ? "Less" : "Show"}
                </p>
              </div>
            </div>

            {show && selectedIndex === index && (
              <div className={`${styles.timeline} bg-[#E9ECEB] p-5`}>
                <TimeLine items={order?.statuses} />
              </div>
            )}
          </div>
          <a
            style={{ position: "absolute", right: "10px", bottom: "10px" }}
            href="tel:+918921951010"
          >
            Contact us
          </a>
        </div>
      ))}
    </div>
  );
};

export default MyOrders;
