import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineStorefront } from "react-icons/md";
import React, { useContext, useEffect, useState } from "react";

import { UserStates } from "../../context/UserContext";
import {
  deleteMarketPlaceUrl,
  getAllMarketPlaceListings,
  getMyMarketplacesUrl,
} from "../../apis/constants";
import { NavbarDefault } from "../../Components/Home/Navbar";
import GoBackAndSearch from "../../Components/GoBackAndSearch";
import styles from "./marketplace.module.css";
import PaginationBtns from "../../Components/paginationBtns";
import { Delete, Edit } from "@mui/icons-material";

const MarketPlacePage = () => {
  const accessToken = Cookies.get("gefmartUserToken");

  const params = useParams();
  const navigate = useNavigate();

  const {
    currentLocation,
    userId,
    currentPage,
    setCurrentPage,
    search,
    setSearch,
  } = useContext(UserStates);

  const { id } = params;

  const [allItems, setAllItems] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [myTotalPages, setMyTotalPages] = useState(1);
  const [marketplaceListings, setMarketplaceListings] = useState([]);
  const [myMarketplaceListings, setMyMarketplaceListings] = useState([]);

  const getMarketPlaceItems = (page) => {
    const config = {
      method: "post",
      url: `${getAllMarketPlaceListings}/${page ? page : 1}`,
      data: {
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
        categoryId: id,
        search,
      },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setCurrentPage(Number(res.data.currentPage));
        setMarketplaceListings(res.data.data);
      })
      .catch((err) => {
        setMarketplaceListings([]);
      });
  };

  // get my marketplace listing
  const getMyMarketPlaceItems = (page) => {
    const config = {
      method: "post",
      url: getMyMarketplacesUrl,
      data: { userId, page },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setMyTotalPages(res.data.totalPages);
        setMyMarketplaceListings(res.data.data);
        setCurrentPage(Number(res.data.currentPage));
      })
      .catch((err) => {
        setMarketplaceListings([]);
      });
  };

  // delete markeplace listing
  const deleteMarketplace = (id) => {
    const config = {
      method: "patch",
      url: `${deleteMarketPlaceUrl}${id}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        const marketplaceListings = myMarketplaceListings.filter(
          (item) => item._id !== id
        );
        setMyMarketplaceListings(marketplaceListings);
      })
      .catch((err) => console.log("error:", err));
  };

  const showMyListings = () => {
    getMyMarketPlaceItems(1);
    setAllItems(false);
  };

  const showAllListings = () => {
    console.log("getting marketplace from showAllListings");
    getMarketPlaceItems(1);
    setAllItems(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let loop = 1;
  useEffect(() => {
    loop += 1;
    if (loop > 1) {
      getMarketPlaceItems(currentPage);
    }
  }, [search]);

  return (
    <div style={{ background: "#d3d3d391" }}>
      <NavbarDefault />
      <GoBackAndSearch
        title={"Marketplace"}
        placeholder={"marketplace"}
        setSearchKeyword={setSearch}
        searchKeyword={search}
      />
      <div className={styles.marketplaceItemsContainer}>
        <div className={styles.selectionContainer}>
          <div className={styles.sectionSelection}>
            <div className={styles.heading} onClick={showAllListings}>
              <p>Marketplace</p>
              <span
                className={styles.span}
                style={{
                  borderBottom: allItems ? "4px solid darkgreen" : "2px solid",
                }}
              />
            </div>
            <div className={styles.heading} onClick={showMyListings}>
              <p>My Listings</p>
              <span
                className={styles.span}
                style={{
                  borderBottom: !allItems ? "4px solid darkgreen" : "2px solid",
                }}
              />
            </div>
          </div>
          <div></div>
        </div>
        {!allItems && (
          <button className={styles.addBtn} onClick={() => navigate("add")}>
            Add Product to Marketplace
          </button>
        )}
        <div className={styles.parentContainer}>
          {marketplaceListings.length === 0 ? (
            <div
              style={{
                borderRadius: 5,
                marginBottom: "5px",
                textAlign: "center",
                padding: "100px 0",
                width: "100vw",
              }}
            >
              No products
            </div>
          ) : (
            <>
              {(allItems ? marketplaceListings : myMarketplaceListings).map(
                (item) => (
                  <div className={styles.item} key={item._id}>
                    <div
                      className={`${styles.singleItem} w-96 p-3 border shadow-md gap-2 rounded-md cursor-pointer`}
                    >
                      <div className="flex gap-2 justify-between">
                        <div
                          className="w-5/12"
                          onClick={() => navigate(`/marketplace/${item._id}`)}
                        >
                          <img
                            src={item.images[0]}
                            alt="gefmart marketplace item"
                            className="w-44 object-cover"
                            style={{
                              width: "120px",
                              height: "120px",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        <div
                          onClick={() => navigate(`/marketplace/${item._id}`)}
                          className="w-7/12"
                        >
                          <div className="flex w-full justify-between gap-4 mb-1">
                            <h1
                              className={`${styles.itemName} text-[#013220] text-md mr-1`}
                            >
                              {item.name}
                            </h1>
                          </div>

                          <div className="flex gap-1 oneLineText">
                            <p>
                              <IoLocationOutline className="text-[#00BF63]" />
                            </p>
                            <p className="text-sm">
                              {item.address?.city?.trim() &&
                              item.address?.city !== "undefined" &&
                              item.address?.city !== "null" &&
                              item.address?.district?.trim() &&
                              item.address?.district !== "undefined" &&
                              item.address?.district !== "null" ? (
                                `${item.address.city}, ${item.address.district}`
                              ) : item.address?.city?.trim() &&
                                item.address?.city !== "undefined" &&
                                item.address?.city !== "null" ? (
                                item.address.city
                              ) : item.address?.district?.trim() &&
                                item.address?.district !== "undefined" &&
                                item.address?.district !== "null" ? (
                                item.address.district
                              ) : (
                                <span className="text-red-600">
                                  User's address not found
                                </span>
                              )}
                            </p>
                          </div>

                          <div className="flex gap-1 oneLineText">
                            <MdOutlineStorefront className="text-[#00BF63]" />
                            <p className="text-sm">{item.user.business_name}</p>
                          </div>
                          {item.hidden_price ||
                          item?.retail_price === undefined ||
                          item?.wholesale_price === undefined ? (
                            <p className="text-sm font-medium text-[#00BF63] mt-2">
                              * contact for price
                            </p>
                          ) : (
                            <p className="text-lg font-medium text-[#00BF63] mt-2">
                              ₹{item?.retail_price || item?.wholesale_price}/pc
                            </p>
                          )}
                          <span className="text-sm text-slate-400">
                            {dayjs(item.createdAt).format(
                              "DD-MMM-YYYY hh:mm A"
                            )}
                          </span>
                        </div>
                        {!allItems && (
                          <div style={{ display: "flex" }}>
                            <Edit onClick={() => navigate(`add/${item._id}`)} />
                            <Delete
                              onClick={() => deleteMarketplace(item._id)}
                              className="text-red-800"
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex w-full justify-between mt-2">
                        <div className="flex items-center bg-[#013220] text-white px-9 rounded-md py-1 gap-2">
                          <a
                            href={`tel:+91${item.user.number}`}
                            className="contactBtn"
                          >
                            <IoMdCall />
                            <p style={{ marginLeft: "5px" }}>Call</p>
                          </a>
                        </div>
                        <div
                          className="flex items-center text-[#013220] border border-[#013220] bg-white rounded-md py-1 justify-center"
                          style={{ width: "60%" }}
                        >
                          <a
                            // href={`https://api.whatsapp.com/send?phone=+91${item.user.number}&text=Hello,%20I%20am%20interested%20in%20buying%20your%20product%20${item.name}%20from%20Gefmart%20Marketplace https://gefmart.com/marketplace/${item._id}`}
                            href={`https://api.whatsapp.com/send?phone=+91${
                              item.user.number
                            }&text=${encodeURIComponent(
                              `Hello, I am interested in buying your product ${item.name} from Gefmart Marketplace https://gefmart.com/marketplace/${item._id}`
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                            className="contactBtn"
                          >
                            <FaWhatsapp />
                            <p style={{ marginLeft: "5px" }}>
                              {item.hidden_price ? "Request Price" : "Chat"}
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>

      <PaginationBtns
        totalPages={allItems ? totalPages : myTotalPages}
        getItems={allItems ? getMarketPlaceItems : getMyMarketPlaceItems}
        search={search}
        style={{ position: "unset" }}
      />
      {/* <Footer /> */}
    </div>
  );
};

export default MarketPlacePage;
